import { getMinimalProductDataFromHandle } from "~/~/lib/shopify/queries/get-product-data"

import type { Nullish } from "~/@types/generics"
import { getSfProduct } from "~/lib/shopify/queries/get-sf-product"
import { serializeSfQuickBuy } from "~/components/ui/ProductCardSlider/Layout/QuickBuy/_data/serializer"

export async function getProductQuickbuyVariants(handle: Nullish<string>, locale: string) {
  if (!handle) return []
  const { productByHandle } = await getMinimalProductDataFromHandle({ slug: handle, locale })

  if (!productByHandle?.id) return []
  const { product } = await getSfProduct({
    id: productByHandle?.id,
    locale,
    nextTags: [productByHandle?.id],
  })

  if (!product) return []

  return serializeSfQuickBuy(product, locale) ?? []
}

import { useQuery } from "@tanstack/react-query"
import { getMinimalProductDataFromHandle } from "~/~/lib/shopify/queries/get-product-data"

import type { InferReturn } from "~/@types/generics"
import { DEFAULT_LOCALE } from "~/lib/i18n/constants"
import { getSfProduct } from "~/lib/shopify/queries/get-sf-product"
import type { getSfProductByHandle } from "~/lib/shopify/queries/get-sf-product-by-handle"

type TData = InferReturn<typeof getSfProduct> | null

export function useSfProductByHandleQuery<TError = unknown>(
  params: Parameters<typeof getSfProductByHandle>[0],
  options?: Parameters<typeof useQuery<TData, TError, TData, [Parameters<typeof getSfProductByHandle>[0]]>>[2]
) {
  return useQuery<TData, TError, TData, [Parameters<typeof getSfProductByHandle>[0]]>(
    [params],
    async () => {
      const { handle, locale, ...rest } = params
      const { productByHandle } = await getMinimalProductDataFromHandle({
        slug: handle,
        locale: locale ?? DEFAULT_LOCALE,
      })
      if (!productByHandle?.id) return null
      return await getSfProduct({
        id: productByHandle.id,
        locale,
        ...rest,
        nextTags: [...rest.nextTags, productByHandle.id],
      })
    },
    {
      // Set StaleTime to revalidate data after 15 minutes
      staleTime: 15 * 60 * 1000,
      ...options,
    }
  )
}

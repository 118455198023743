import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { PropsWithClassName } from "~/@types/generics"
import { productMetafields, productVariantMetafields } from "~/lib/shopify/constants"
import { getSfProducts } from "~/lib/shopify/queries/get-sf-products"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { objectValues } from "~/utils/object-values"
import type { ProductPageProps } from "~/app/[locale]/products/[slug]/page"

type LinkedProductsProps = PropsWithClassName<
  {
    product: UnlikelyProduct
  } & ProductPageProps
>

export type FetchLinkedProductsParams = Pick<LinkedProductsProps, "product"> &
  Pick<LinkedProductsProps["params"], "locale">

export async function fetchLinkedProducts({ product, locale }: FetchLinkedProductsParams) {
  const productsRef = product?.variants?.[0]?.sku?.split("\\")?.[0] ?? null

  if (!productsRef || !product?.id) return { products: [] }

  return await getSfProducts({
    locale: locale,
    metafieldKeys: objectValues(getLocalizedMetafields(productMetafields, locale)),
    metafieldVariantsKeys: objectValues(getLocalizedMetafields(productVariantMetafields, locale)),
    query: `${productsRef}*`,
    nextTags: [product?.id],
  })
}
